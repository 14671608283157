import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ReactGA from "react-ga4";
import 'bulma/css/bulma.min.css'; // Import Bulma CSS



(async () => {
  await ReactGA.initialize("G-9CEPDLWKT7");

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
})();