// src/components/LandingPage.js
import React from 'react';
import Waitlist from './Waitlist';

function LandingPage() {
    return (
        <div>
            <section className="hero is-primary is-bold">
                <div className="hero-body">
                    <div className="container">
                        <h1 className="title">Wingman</h1>
                        <h2 className="subtitle">Wingman to your dating game</h2>
                        {/* <a href="#signup" className="button is-primary is-large">Join Waitlist</a> */}
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <h3 className="title is-3">3 simple steps</h3>
                    <ul>
                        <li><span className="icon"><i className="fas fa-check"></i></span> Take a screenshot on any dating app or social media conversation</li>
                        <li><span className="icon"><i className="fas fa-check"></i></span> Upload it to wingman</li>
                        <li><span className="icon"><i className="fas fa-check"></i></span> Get responses curated for your conversation</li>
                    </ul>
                </div>
            </section>

            {/* <section className="section">
                <div className="container">
                    <h3 className="title is-3">Testimonials</h3>
                    <blockquote>
                        <p>"[Testimonial text]"</p>
                        <footer>- John Doe, CEO at Example Inc.</footer>
                    </blockquote>
                </div>
            </section>

            <section className="section" id="signup">
                <div className="container">
                    <h3 className="title is-3">Join Our Waitlist</h3>
                    <form action="/submit-waitlist" method="POST">
                        <div className="field is-grouped">
                            <div className="control is-expanded">
                                <input className="input" type="email" name="email" placeholder="Enter your email" required />
                            </div>
                            <div className="control">
                                <button type="submit" className="button is-primary">Join Now</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section> */}

            <footer className="footer">
                <div className="content has-text-centered">
                    <p>
                        <a href="/privacy-policy">Privacy Policy</a>
                        {/* <a href="/terms-of-service">Terms of Service</a> | */}
                        {/* <a href="/about">About Us</a> */}
                    </p>
                    {/* <div className="social-icons">
                        <a href="#"><i className="fab fa-twitter"></i></a>
                        <a href="#"><i className="fab fa-facebook"></i></a>
                        <a href="#"><i className="fab fa-instagram"></i></a>
                    </div> */}
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;
