// src/components/Navbar.js
import React from 'react';

function Navbar() {
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-menu">
        <div className="navbar-start">
          <a href="#about" className="navbar-item">About Us</a>
          <a href="#services" className="navbar-item">Services</a>
          <a href="#contact" className="navbar-item">Contact</a>
          <a href="#wingman" className="navbar-item">Wingman</a>
        </div>
        <div className="navbar-end">
          <a href="#waitlist" className="navbar-item">Join Waitlist</a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
