// src/components/PrivacyPolicy.js
import React, { useEffect } from 'react';

function PrivacyPolicy() {
    useEffect(() => {
        // Redirect to the HTML page
        window.location.href = '/privacy.html';
    }, []);

    return (
        <div>
            {/* Optional: You can render some content here if needed */}
            <h1>Redirecting...</h1>
        </div>
    );
}

export default PrivacyPolicy;
