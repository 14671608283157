// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Switch, Link } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ReactGA from "react-ga4";


function App() {

  useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Custom App"});
    }, []); // Dependency array with ga ensures useEffect runs when ga is initialized

  return (
    <Router>
      <Navbar/>
      <div>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </div>
      <Footer/>
    </Router>
  );
}


export default App;
